.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading .ant-spin {
  color: #3e79f7;
}

::-webkit-scrollbar{
  width: 6px;
  height: 6px;
  background-color: #fff;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(115, 183, 242, 0.3);
  border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover {
  background: #3999ec;
  cursor: pointer;
}

.modalTabStyle {
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.modalTabStyle .ant-modal-header {
  padding: 0 !important;
}

.modalTabStyle .ant-tabs-nav {
  margin: 0 !important;
}

.modalTabStyle .ant-tabs-bottom .ant-tabs-nav {
  margin: 0 !important;
}

#merlin-chat{
  display: none;
}

.ant-image-preview-wrap {
  top: 11% !important;
  right: 11% !important;
  bottom: 11% !important;
  left: 11% !important;
}
.ant-image-preview-mask {
    top: 11% !important;
    right: 11% !important;
    bottom: 11% !important;
    left: 11% !important;
    height: 78% !important;
}

.ant-image-mask-info {
  width: 23px;
}

.hiddenPrinView {
  display: none;
}

@media print {
  .hidden-print,
  tr.hidden-print,
  th.hidden-print,
  td.hidden-print{
    display:none !important;
  }
  .hiddenPrinView {
    color: #0e1b2c;
    display: block;
  }
}
